



































































































































































































































































































































































































































































































































































import {
  defineComponent,
  computed,
  reactive,
  onMounted,
  onUnmounted,
  ref,
  watch,
  getCurrentInstance,
  type ComputedRef,
  type PropType,
} from '@vue/composition-api';
import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import KeyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import ForkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import CustomFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import CustomFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import CustomFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import KeyPlayerGrayIcon from 'src/components/Staffs/keyPlayerGrayIcon.vue';
import ForkliftGrayIcon from 'src/components/Staffs/forkliftGrayIcon.vue';
import CustomFlagGrayIcon from 'src/components/Staffs/customFlagGrayIcon.vue';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { vvHasError, vvGetError, vvReset, vvValidate } from 'src/util/vee_validate';
import { packToTimeIntegerWithGuard } from 'src/util/datetime';
import { StaffGenderSelectOptions, StaffTypeSelectOptions } from 'src/consts';
import type { Staff } from 'src/models/staff';
import type { WorkplaceExtension } from 'src/models/workplaceExtension';
import type { TimetableMaster } from 'src/models/timetableMaster';
import type { MacroOperationMaster } from 'src/models/macroOperationMaster';
import type { ShiftPattern } from 'src/models/workplaceMasters/shiftPattern';
import {
  getDayValue,
  convertToStaffWorkScheduleFormValues,
  updateCandidateByShiftPattern,
  type WeekKey,
  type WeekName,
  type LabelName,
  type SaveCandidate,
  type StaffWorkScheduleFormValues,
} from './setting';
import ShiftPatternSelectModal from './components/ShiftPatternSelectModal.vue';
import { useShiftPatternSelectModal } from './composables/useShiftPatternSelectModal';
import { useDutyTimeValidation } from './composables/useDutyTimeValidation';

const MEMO_MAX_LENGTH = 255;

const week: { key: WeekKey; name: WeekName; value: number }[] = [
  { key: 'mon', name: '月', value: 1 },
  { key: 'tue', name: '火', value: 2 },
  { key: 'wed', name: '水', value: 3 },
  { key: 'thu', name: '木', value: 4 },
  { key: 'fri', name: '金', value: 5 },
  { key: 'sat', name: '土', value: 6 },
  { key: 'sun', name: '日', value: 0 },
];

type State = {
  isLoaded: boolean;
  saveCandidate: SaveCandidate;
  staffWorkScheduleFormValues: StaffWorkScheduleFormValues[];
  timetableMasters: TimetableMaster[];
  macroOperationMasters: MacroOperationMaster[];
  isSelectedTimeTableMastersUnique: boolean;
  validations: Record<string, Record<string, unknown>>;
};

type DisplayStaffInfo = {
  fullName: string;
  staffAgencyName: string;
  staffLabelName: string;
};

function getSaveCandidateFromStaff(staff: Staff): SaveCandidate {
  // スタッフスキル初期値の生成
  const priorities = [1, 2, 3, 4, 5];
  const staffSkills = priorities.map((priority) => {
    const staffSkill = staff.staff_skills.find((staffSkill) => staffSkill.priority === priority);
    const timetableMasterId = staffSkill ? staffSkill.timetable_master_id : null;
    return {
      timetableMasterId,
      priority,
    };
  });

  // スタッフワークスケジュール初期値の生成
  const dayOfWeeks = week.map((day) => day.value);
  const staffWorkSchedules = dayOfWeeks.map((dayOfWeek) => {
    const staffWorkSchedule = staff.staff_extension?.staff_work_schedules.find(
      (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayOfWeek,
    );
    return {
      day_of_week: dayOfWeek,
      work_start_time: staffWorkSchedule?.work_start_time ?? null,
      work_end_time: staffWorkSchedule?.work_end_time ?? null,
      break1_start_time: staffWorkSchedule?.break1_start_time ?? null,
      break1_end_time: staffWorkSchedule?.break1_end_time ?? null,
      break2_start_time: staffWorkSchedule?.break2_start_time ?? null,
      break2_end_time: staffWorkSchedule?.break2_end_time ?? null,
      duty1_macro_operation_master_id: staffWorkSchedule?.duty1_macro_operation_master_id ?? null,
      duty1_start_time: staffWorkSchedule?.duty1_start_time ?? null,
      duty1_end_time: staffWorkSchedule?.duty1_end_time ?? null,
      duty2_macro_operation_master_id: staffWorkSchedule?.duty2_macro_operation_master_id ?? null,
      duty2_start_time: staffWorkSchedule?.duty2_start_time ?? null,
      duty2_end_time: staffWorkSchedule?.duty2_end_time ?? null,
    };
  });
  return {
    staffId: staff.id,
    staffType: staff.staff_extension?.staff_type ?? 1,
    macroOperationMasterId: staff.staff_extension?.macro_operation_master_id ?? null,
    gender: staff.staff_extension?.gender ?? 0,
    isKeyPlayer: staff.staff_extension?.is_key_player ?? false,
    isForkman: staff.staff_extension?.is_forkman ?? false,
    hasCustomSkill1: staff.staff_extension?.has_custom_skill1 ?? false,
    hasCustomSkill2: staff.staff_extension?.has_custom_skill2 ?? false,
    hasCustomSkill3: staff.staff_extension?.has_custom_skill3 ?? false,
    memo: staff.staff_extension?.memo ?? '',
    staffSkills: staffSkills,
    staffWorkSchedules,
  };
}

function getValidationMaps(state: State): Record<string, Object> {
  const ruleHour = { numeric: true, max: 2, max_value: 47 };
  const ruleMin = { numeric: true, max: 2, regex: /^(00|15|30|45)$/ };
  const staticRules = {
    work_start_time_hour: ruleHour,
    work_start_time_min: ruleMin,
    work_end_time_hour: ruleHour,
    work_end_time_min: ruleMin,
    break1_start_time_hour: ruleHour,
    break1_start_time_min: ruleMin,
    break1_end_time_hour: ruleHour,
    break1_end_time_min: ruleMin,
    break2_start_time_hour: ruleHour,
    break2_start_time_min: ruleMin,
    break2_end_time_hour: ruleHour,
    break2_end_time_min: ruleMin,
  };

  const { rules: dynamicRules } = useDutyTimeValidation(week, state.staffWorkScheduleFormValues);
  return { ...staticRules, ...dynamicRules };
}

export default defineComponent({
  components: {
    KeyPlayerIcon,
    ForkliftIcon,
    CustomFlag1Icon,
    CustomFlag2Icon,
    CustomFlag3Icon,
    KeyPlayerGrayIcon,
    ForkliftGrayIcon,
    CustomFlagGrayIcon,
    ShiftPatternSelectModal,
  },
  name: 'StaffExtensionEditModal',
  props: {
    staff: {
      type: Object as PropType<Staff>,
      required: true,
    },
    resourceName: {
      type: String,
      required: false,
      default: 'スタッフ',
    },
    workplaceExtension: {
      type: Object as PropType<WorkplaceExtension>,
      required: true,
    },
  },
  emits: ['close', 'update'],
  setup(props, { emit }) {
    const vueInstance = getCurrentInstance()?.proxy.$root!;

    const userId: ComputedRef<number> = wrappedMapGetters(vueInstance.$store, 'user', ['id']).id;
    const workplaceId = computed(() => {
      return Number(vueInstance.$route.params.workplaceId);
    });

    const state: State = reactive({
      isLoaded: false,
      saveCandidate: getSaveCandidateFromStaff(props.staff),
      staffWorkScheduleFormValues: [],
      timetableMasters: [],
      macroOperationMasters: [],
      isSelectedTimeTableMastersUnique: computed(() => {
        const timetableMasterIds = state.saveCandidate.staffSkills
          .map((staffSkill) => staffSkill.timetableMasterId)
          .filter((id): id is number => id !== null && id !== 0);
        const uniqueTimetableMasterIdSet = new Set(timetableMasterIds);
        return timetableMasterIds.length === uniqueTimetableMasterIdSet.size;
      }),
      validations: computed(() => getValidationMaps(state)),
    });
    state.staffWorkScheduleFormValues = state.saveCandidate.staffWorkSchedules.map(
      convertToStaffWorkScheduleFormValues,
    );

    const displayStaffInfo = computed<DisplayStaffInfo>(() => {
      return {
        fullName: `${props.staff.family_name} ${props.staff.first_name}`,
        staffAgencyName: props.staff.staff_agency?.name ?? '',
        staffLabelName: props.staff.staff_label?.label_name ?? '',
      };
    });

    const genderOptions = StaffGenderSelectOptions;
    const staffTypeOptions = StaffTypeSelectOptions.filter((option) => option.id !== 0);

    const isMemoInvalid = (memo: string): boolean => {
      return memo.length > MEMO_MAX_LENGTH;
    };

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }
    const hasError = computed(() => {
      return vvHasError(vueInstance) || isMemoInvalid(state.saveCandidate.memo);
    });
    function clearErrors() {
      vvReset(vueInstance);
    }

    async function load(staff: Staff) {
      state.timetableMasters = await timetableMasterApi.index({
        workplaceId: workplaceId.value,
        params: {
          budget_group_id: staff.budget_group_id,
          is_enabled: true,
          use_in_prep_phase: true,
        },
      });
      state.macroOperationMasters = await macroOperationMasterApi.index({
        workplaceId: workplaceId.value,
        budgetGroupId: staff.budget_group_id,
        params: {
          is_enabled: true,
        },
      });
    }

    async function onChangeStaff() {
      state.saveCandidate = getSaveCandidateFromStaff(props.staff);
      state.staffWorkScheduleFormValues = state.saveCandidate.staffWorkSchedules.map(
        convertToStaffWorkScheduleFormValues,
      );
      await load(props.staff);
    }

    watch(() => props.staff, onChangeStaff);

    const breakNumbers = [1, 2] as const;
    const extraDutyNumbers = [1, 2] as const;

    async function onChangeTime(key: WeekKey, name: LabelName) {
      const dayOfWeek = getDayValue(key);
      if (dayOfWeek < 0) {
        return;
      }
      const staffWorkScheduleFormValue = state.staffWorkScheduleFormValues.find(
        (formValue) => formValue.day_of_week === dayOfWeek,
      );
      const staffWorkSchedule = state.saveCandidate.staffWorkSchedules.find(
        (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayOfWeek,
      );
      if (staffWorkScheduleFormValue !== undefined && staffWorkSchedule !== undefined) {
        staffWorkSchedule[name] = packToTimeIntegerWithGuard(
          staffWorkScheduleFormValue[`${name}_hour`],
          staffWorkScheduleFormValue[`${name}_min`],
          0,
        );
      }
      if (name.match('duty1')) {
        vvValidate(vueInstance, `${key}Duty1Time`);
      } else if (name.match('duty2')) {
        vvValidate(vueInstance, `${key}Duty2Time`);
      } else {
        vvValidate(vueInstance, key);
      }
    }

    async function onChangeDutyMacroOperationMaster(day: WeekKey, num: number) {
      if (num !== 1 && num !== 2) {
        return;
      }

      const dayValue = getDayValue(day);
      const staffWorkScheduleFormValue = state.staffWorkScheduleFormValues.find(
        (staffWorkScheduleFormValue) => staffWorkScheduleFormValue.day_of_week === dayValue,
      );
      if (staffWorkScheduleFormValue === undefined) {
        return;
      }

      const macroOperationMasterIdInput = staffWorkScheduleFormValue[`duty${num}_macro_operation_master_id`];
      const staffWorkSchedule = state.saveCandidate.staffWorkSchedules.find(
        (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayValue,
      );
      if (staffWorkSchedule === undefined) {
        return;
      }
      staffWorkSchedule[`duty${num}_macro_operation_master_id`] = macroOperationMasterIdInput;
      staffWorkSchedule[`duty${num}_start_time`] =
        macroOperationMasterIdInput !== null && macroOperationMasterIdInput !== 0
          ? staffWorkSchedule[`duty${num}_start_time`]
          : null;
      staffWorkSchedule[`duty${num}_end_time`] =
        macroOperationMasterIdInput !== null && macroOperationMasterIdInput !== 0
          ? staffWorkSchedule[`duty${num}_end_time`]
          : null;

      if (macroOperationMasterIdInput !== null && macroOperationMasterIdInput !== 0) {
        vvValidate(vueInstance, `${day}Duty${num}StartTimeHour`);
        vvValidate(vueInstance, `${day}Duty${num}StartTimeMin`);
        vvValidate(vueInstance, `${day}Duty${num}EndTimeHour`);
        vvValidate(vueInstance, `${day}Duty${num}EndTimeMin`);
        vvValidate(vueInstance, `${day}Duty${num}Time`);
      } else {
        state.staffWorkScheduleFormValues.forEach((staffWorkScheduleFormValue) => {
          if (staffWorkScheduleFormValue.day_of_week === dayValue) {
            staffWorkScheduleFormValue[`duty${num}_start_time_hour`] = null;
            staffWorkScheduleFormValue[`duty${num}_start_time_min`] = null;
            staffWorkScheduleFormValue[`duty${num}_end_time_hour`] = null;
            staffWorkScheduleFormValue[`duty${num}_end_time_min`] = null;
          }
        });
        vvReset(vueInstance, `${day}Duty${num}StartTimeHour`);
        vvReset(vueInstance, `${day}Duty${num}StartTimeMin`);
        vvReset(vueInstance, `${day}Duty${num}EndTimeHour`);
        vvReset(vueInstance, `${day}Duty${num}EndTimeMin`);
        vvReset(vueInstance, `${day}Duty${num}Time`);
      }
    }

    function attachValidate() {
      week.forEach((dayOfWeek) => {
        vueInstance.$validator.attach({
          name: dayOfWeek.key,
          rules: 'custom_after2',
          getter: () => {
            const staffWorkSchedule = state.saveCandidate.staffWorkSchedules.find(
              (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayOfWeek.value,
            );
            if (staffWorkSchedule === undefined) {
              return;
            }
            return {
              start_time: staffWorkSchedule.work_start_time,
              end_time: staffWorkSchedule.work_end_time,
              break1_start_time: staffWorkSchedule.break1_start_time,
              break1_end_time: staffWorkSchedule.break1_end_time,
              break2_start_time: staffWorkSchedule.break2_start_time,
              break2_end_time: staffWorkSchedule.break2_end_time,
            };
          },
        });
        vueInstance.$validator.attach({
          name: `${dayOfWeek.key}Duty1Time`,
          rules: 'custom_after1',
          getter: () => {
            const staffWorkSchedule = state.saveCandidate.staffWorkSchedules.find(
              (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayOfWeek.value,
            );
            if (staffWorkSchedule === undefined) {
              return;
            }
            return {
              start_time: staffWorkSchedule.duty1_start_time,
              end_time: staffWorkSchedule.duty1_end_time,
              disallow_equal: true,
            };
          },
        });
        vueInstance.$validator.attach({
          name: `${dayOfWeek.key}Duty2Time`,
          rules: 'custom_after1',
          getter: () => {
            const staffWorkSchedule = state.saveCandidate.staffWorkSchedules.find(
              (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayOfWeek.value,
            );
            if (staffWorkSchedule === undefined) {
              return;
            }
            return {
              start_time: staffWorkSchedule.duty2_start_time,
              end_time: staffWorkSchedule.duty2_end_time,
              disallow_equal: true,
            };
          },
        });
      });
    }

    function detachValidate() {
      week.forEach((day) => {
        vueInstance.$validator.detach(day.key);
        vueInstance.$validator.detach(`${day.key}Duty1Time`);
        vueInstance.$validator.detach(`${day.key}Duty2Time`);
      });
    }

    async function save() {
      if (!(await vvValidate(vueInstance))) {
        return;
      }
      await update();
    }

    function close() {
      emit('close');
    }

    async function update(): Promise<void> {
      try {
        // 入力されていない優先度のスタッフスキルはリクエストに含めない
        const staffSkills = state.saveCandidate.staffSkills
          .filter(
            (staffSkill): staffSkill is { timetableMasterId: number; priority: number } =>
              staffSkill.timetableMasterId !== null,
          )
          .map((staffSkill) => {
            return {
              timetable_master_id: staffSkill.timetableMasterId,
              priority: staffSkill.priority,
            };
          });

        const staff = await staffExtensionApi.update({
          workplaceId: workplaceId.value,
          data: {
            staff_id: state.saveCandidate.staffId,
            staff_type: state.saveCandidate.staffType,
            macro_operation_master_id: state.saveCandidate.macroOperationMasterId,
            gender: state.saveCandidate.gender,
            is_key_player: state.saveCandidate.isKeyPlayer,
            is_forkman: state.saveCandidate.isForkman,
            has_custom_skill1: state.saveCandidate.hasCustomSkill1,
            has_custom_skill2: state.saveCandidate.hasCustomSkill2,
            has_custom_skill3: state.saveCandidate.hasCustomSkill3,
            memo: state.saveCandidate.memo,
            staff_skills: staffSkills,
            staff_work_schedules: state.saveCandidate.staffWorkSchedules,
          },
        });
        emit('update', staff);
        close();
        notifySuccess1(vueInstance, `${props.resourceName}を編集しました`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'dup_timetable_master') {
          const msg = '同じ担当工程は選択できません。';
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else {
          const msg = `${props.resourceName}の編集に失敗しました。管理者に連絡してください。(ERR: ERR00002, user_id:${userId.value})`;
          notifyError1(vueInstance, msg, { err });
        }
      }
    }

    // 「シフトパターンから設定」関連

    const selectedShifts = ref<WeekKey[]>([]);

    const { shiftPatterns, showsShiftPatternSelectModal, showShiftPatternSelectModal, hideShiftPatternSelectModal } =
      useShiftPatternSelectModal();

    const handleClickShiftPatternSettingButton = () => {
      const budgetGroup = props.staff.budget_group ?? null;
      if (budgetGroup !== null) {
        showShiftPatternSelectModal(budgetGroup);
      }
    };

    const handleSelectShiftPattern = (shiftPattern: ShiftPattern) => {
      updateCandidateByShiftPattern(
        state.saveCandidate,
        state.staffWorkScheduleFormValues,
        selectedShifts.value,
        shiftPattern,
      );
    };

    const gatedFuncGenerator = getGatedFuncGenerator();

    onMounted(async () => {
      state.isLoaded = false;
      attachValidate();
      await load(props.staff);
      state.isLoaded = true;
    });

    onUnmounted(() => {
      detachValidate();
    });

    return {
      state,
      close,
      displayStaffInfo,
      genderOptions,
      staffTypeOptions,
      isMemoInvalid,
      hasError,
      getError,
      clearErrors,
      breakNumbers,
      extraDutyNumbers,
      onChangeDutyMacroOperationMaster,
      saveItem: gatedFuncGenerator.makeAsyncFuncGated(save),
      week,
      onChangeTime,
      selectedShifts,
      shiftPatterns,
      showsShiftPatternSelectModal,
      hideShiftPatternSelectModal,
      handleClickShiftPatternSettingButton,
      handleSelectShiftPattern,
    };
  },
});
