import { STAFF_GENDER } from 'src/consts';

export const DEFAULT_MONTH_RANGE = 5;

export const SKILL_MAP = {
  STAR: 1,
  FORK_LIFT: 2,
  CUSTOM_1: 3,
  CUSTOM_2: 4,
  CUSTOM_3: 5,
} as const;

export const SKILLS = [SKILL_MAP.STAR, SKILL_MAP.FORK_LIFT, SKILL_MAP.CUSTOM_1, SKILL_MAP.CUSTOM_2, SKILL_MAP.CUSTOM_3];

export type Skill = (typeof SKILL_MAP)[keyof typeof SKILL_MAP];

export const STAFF_GENDER_LABEL_MAP = {
  [STAFF_GENDER.NO_ANSWER]: '-',
  [STAFF_GENDER.MALE]: '男',
  [STAFF_GENDER.FEMALE]: '女',
  [STAFF_GENDER.NON_BINARY]: '他',
};
