




































import { defineComponent, type PropType, type DeepReadonly } from '@vue/composition-api';
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern';

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    shiftPatterns: {
      type: Array as PropType<DeepReadonly<ShiftPattern[]>>,
      required: true,
    },
  },
  setup(_, { emit }) {
    const handleSelect = (shiftPattern: ShiftPattern) => {
      emit('select', shiftPattern);
      emit('close');
    };
    return {
      handleSelect,
    };
  },
});
