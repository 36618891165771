import axios from 'axios';
import * as utils from 'src/apis/apiUtils';
import { StringOrNumber } from 'src/models/common';
import { GetAttendanceStatusParams, GetStaffMonthlySummaryParams } from 'src/models/api/staffRequest';
import { AttendanceStatus } from 'src/models/staff';
import { StaffMonthlySummaries } from 'src/models/staffMonthlySummaryByTimetableMaster';

export default {
  async getAttendanceStatus({
    workplaceId,
    staffId,
    params,
  }: {
    workplaceId: StringOrNumber;
    staffId: number;
    params: GetAttendanceStatusParams;
  }): Promise<AttendanceStatus> {
    const { data } = await axios.get(
      utils.getApiUrl(`/workplaces/${workplaceId}/staffs/${staffId}/attendance_status`),
      { params },
    );
    return data;
  },
  async getStaffMonthlySummaries({
    workplaceId,
    staffId,
    params,
  }: {
    workplaceId: StringOrNumber;
    staffId: number;
    params: GetStaffMonthlySummaryParams;
  }): Promise<StaffMonthlySummaries> {
    const { data } = await axios.get(
      utils.getApiUrl(`/workplaces/${workplaceId}/staffs/${staffId}/staff_monthly_summaries`),
      { params },
    );
    return data;
  },
};
