import { STAFF_TYPE, STAFF_GENDER } from 'src/consts';

export const STAFF_TYPE_LABEL_MAP = {
  0: '未設定',
  [STAFF_TYPE.REGULAR]: 'レギュラー',
  [STAFF_TYPE.SPOT]: 'スポット',
};

export const STAFF_GENDER_LABEL_MAP = {
  [STAFF_GENDER.NO_ANSWER]: '未設定',
  [STAFF_GENDER.MALE]: '男',
  [STAFF_GENDER.FEMALE]: '女',
  [STAFF_GENDER.NON_BINARY]: '他',
};
